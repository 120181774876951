export default {
  copy: {
    success: "Text Copied!",
    failure: "Could not copy Text",
  },
  copyGitRevision: {
    success: "Git Revision Copied!",
    failure: "Could not copy Git Revision",
  },
  copyImageUrl: {
    success: "Image URL Copied!",
    failure: "Could not copy Image URL",
  },
  copyVideoUrl: {
    success: "Video URL Copied!",
    failure: "Could not copy Video URL",
  },
  copyShareUrl: {
    success: 'URL "%{url}" Copied!',
    failure: "Could not copy URL",
  },
  copyInviteUrl: {
    success: 'Invite URL "%{url}" Copied!',
    failure: "Could not copy Invite URL",
  },
  copyTwoFactorProvisioningUrl: {
    success: "Provisioning Url Copied!",
    failure: "Could not copy Provisioning Url",
  },
  copyBackupCodes: {
    success: "Backup Codes Copied!",
    failure: "Could not copy Backup Codes",
  },
  signup: {
    success:
      "<b>Welcome to FleetYards.net!</b> Your account has been created. <br>You will receive a confirmation email to activate your account.",
    invalid: "Please resolve the errors before your account can be created.",
    failure: "We could not create your account. Please try again later.",
  },
  accountConfirm: {
    success: "Your account was successfully confirmed.<br>You can now login.",
    failure: "Your account could not be confirmed.",
  },
  requestPasswordChange: {
    success:
      "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
  },
  changePassword: {
    success: "Your password was changed successfully.",
    failure: "Your password could not be updated.",
  },
  updateProfile: { success: "Profile updated." },
  updateHangar: { success: "Hangar Settings updated." },
  updateNotifications: { success: "Notification Settings updated." },
  updateAccount: { success: "Account updated." },
  avatarUpload: {
    success: "Avatar uploaded",
    invalidExtension:
      "The Picture you provided has an invalid Format. Supported Formats are: %{extensions}.",
  },
  hangarImport: {
    success: "Import completed",
    partialSuccess:
      "Import partially completed.<br><br>Not found Ships: <br>%{missing}",
    failure: "Import failed",
    invalidExtension:
      "The Import you provided has an invalid Format. Supported Formats are: %{extensions}.",
  },
  hangarExport: { failure: "Export failed, Please try again later." },
  vehicle: {
    add: {
      success:
        'Your new <b>%{model}</b> will be delivered to your <a href="/hangar">Hangar</a>',
    },
    addToWishlist: {
      success:
        'Your new <b>%{model}</b> was added to your <a href="/hangar/wishlist">Wishlist</a>',
    },
    resetIngame: {
      moveToWishlist: {
        success: "Moved all Ingame bought Ships to your Wishlist.",
        failure: "Could not move all Ingame bought Ships to your Wishlist.",
      },
      removeAll: {
        success: "Removed all Ingame bought Ships from your Hangar.",
        failure: "Could not remove all Ingame bought Ships from your Hangar.",
      },
    },
  },
  model: { onSale: "%{model} now on Sale!" },
  notification: { granted: "Welcome to FleetYards.net Notifications!" },
  twoFactor: {
    enable: {
      success: "Two-factor Authentication enabled!",
      failure: "Could not enable Two-factor Authentication.",
    },
    disable: {
      success: "Two-factor Authentication disabled!",
      failure: "Could not diable Two-factor Authentication.",
    },
    backupCodes: { failure: "Backup Codes could not be generated." },
  },
  error: {
    usernameTaken: "Username is already taken.",
    userNotFound: "User not found",
    urlInvalid: "URL is invalid",
    emailTaken: "Email is already taken.",
    fleetTaken: "Fleet is already taken.",
    serialTaken: "Serial is already taken.",
    textInvalid: "%{field} is invalid",
    hangar: {
      accountRequired:
        'You need to <a href="/sign-up/">Sign up</a> or <a href="/login/">Login</a> to add Ships to your Hangar.<br>Get more Information <a href="/hangar/preview/">here</a>',
    },
    commodityPrice: {
      accountRequired:
        'You need to <a href="/sign-up/">Sign up</a> or <a href="/login/">Login</a> to submit Prices.',
    },
    default:
      "<b>Something wrong happened.</b> <br>Have you tried turning it off and on again?",
  },
  account: {
    destroy: {
      success:
        "<b>Your Account has been destroyed.</b> <br>Sad to see you go :'(",
      failure: "We could not destroy your Account! Please try again later.",
    },
  },
  commodityPrice: {
    create: { success: "Thank you for your price submission." },
  },
  fleet: {
    create: {
      success: "Your Fleet has been created.",
      failure: "Fleet could not be saved.",
    },
    update: {
      success: "Settings saved.",
      failure: "Settings could not be saved.",
    },
    destroy: {
      success: "Your Fleet has been destroyed.",
      failure: "We could not destroy your Fleet.",
    },
    leave: {
      success: "You have left the Fleet.",
      failure: "Could not leave the Fleet.",
    },
    invites: {
      accept: {
        success: "Invite accepted.",
        failure: "Could not accept Invite.",
      },
      decline: {
        success: "Invite declined.",
        failure: "Could not decline Invite.",
      },
      create: { failure: "Could not invite User." },
    },
    members: {
      update: {
        success: "Settings saved.",
        failure: "Settings could not be saved.",
      },
      destroy: {
        success: "Member removed.",
        failure: "Member could not be removed.",
      },
      demote: {
        success: "Member demoted.",
        failure: "Member could not be demoted.",
      },
      promote: {
        success: "Member promoted.",
        failure: "Member could not be promoted.",
      },
      accept: {
        success: "Member accepted.",
        failure: "Member could not be accepted.",
      },
      decline: {
        success: "Member declined.",
        failure: "Member could not be declined.",
      },
    },
  },
  fleetInvite: {
    used: "<b>Join request for %{fleet} send!</b><br>Once your Membership is accepted you will be notified via email",
    failure: "Invite could not be used.",
    notFound: "Invite not found.",
    confirm: 'Are you sure you want to join the Fleet: "%{fleet}"?',
  },
  holoViewer: {
    modelLoader: {
      failure:
        "We could not load the requested 3D model! Please try again later.",
    },
  },
  confirm: {
    vehicle: {
      destroy: "Are you sure you want to remove this Ship from you Hangar?",
    },
    hangarGroup: {
      destroy: "Are you sure you want to remove this Group from you Hangar?",
    },
    hangar: {
      import:
        "Imported ships will be added to your Hangar without matching to exisiting Ships.<br><br>Supported Formats are: Fleetyards Export, Starship42 or HangarXplor.<br><br>Do you want to continue?",
      destroySelected:
        "Are you sure you want to remove all Selected Ships from you Hangar? This Action can't be reverted.",
      destroyAll:
        "Are you sure you want to remove all Ships from you Hangar? This Action can't be reverted.",
    },
    wishlist: {
      destroyAll:
        "Are you sure you want to remove all Ships from you Wishlist? This Action can't be reverted.",
    },
    tradeRoutes: {
      reset: "Are you sure you want to reset all commodity prices?",
    },
    account: {
      destroy:
        "Are you sure you want to destroy your Account? This Action can't be reverted.",
    },
    commodityPrice: {
      destroy:
        "Are you sure you want to destroy this Price? This Action can't be reverted.",
    },
    shopCommodity: {
      destroy:
        "Are you sure you want to destroy this Commodity? This Action can't be reverted.",
    },
    fleet: {
      leave:
        "Are you sure you want to leave the Fleet? This Action can't be reverted.",
      destroy:
        "Are you sure you want to destroy your Fleet? This Action can't be reverted.",
      members: { destroy: "Are you sure you want to remove this Member?" },
      invites: {
        decline:
          "Are you sure you want to decline this Invite? This Action can't be reverted.",
      },
    },
    modal: { dirty: "You have unsaved changes that will be lost." },
    twoFactor: {
      generateBackupCodes:
        "Are you sure you want to regenerate your Two-factor Backup Codes?",
    },
  },
  syncExtension: {
    started: "Syncing your Hangar...",
    success: "Your Hangar has been synced.",
    failure: "Your Hangar could not be synced.",
    notLoggedIn:
      'You need to be logged in on <a href="https://robertsspaceindustries.com" target="_blank">https://robertsspaceindustries.com</a> to sync your Hangar.',
  },
};
