export default {
  showReleased: "Show old Releases",
  hideReleased: "Hide old Releases",
  upgrade: "Update available!",
  backToHome: "Plot new course",
  disableGrouping: "Disable Grouping",
  enableGrouping: "Enable Grouping",
  groupedByModel: "Grouped by Model",
  ungrouped: "Ungrouped",
  destroyAccount: "Destroy Account",
  saveScreenshot: "Save as PNG",
  verify: "Verify",
  findModel: "Type to find a Ship",
  find: "Type to search",
  clearHistory: "Clear History",
  sortBy: "Sort by",
  back: "Back",
  open: "Open",
  copy: "Copy",
  more: "More...",
  showMore: "Show more",
  export: "Export",
  import: "Import",
  here: "here",
  backToTop: "Back to Top",
  cancel: "Cancel",
  loading: "Please wait...",
  ok: "Ok",
  reset: "Reset",
  reload: "Reload",
  confirm: "Confirm",
  moreInfo: "More information",
  showDetailPage: "Detail Page",
  showFilter: "Show Filters",
  hideFilter: "Hide Filters",
  showDetails: "Show Details",
  hideDetails: "Hide Details",
  showGuide: "Show Guide",
  hideGuide: "Hide Guide",
  showFleetchart: "Fleetchart",
  hideFleetchart: "Default view",
  showLabels: "Show Labels",
  hideLabels: "Hide Labels",
  resetZoom: "Reset Zoom",
  supportUs: "Support FleetYards.net",
  login: "Login",
  signUp: "Sign Up",
  confirmAccess: "Confirm password",
  "reset-password": "Forgot password?",
  edit: "Edit",
  done: "Done",
  share: "Share",
  change: "Change",
  upload: "Upload",
  editCommodities: "Edit commodities",
  save: "Save",
  remove: "Remove",
  delete: "Delete",
  deleteSelected: "Delete Selected",
  close: "Close",
  filter: "Filter",
  showStatusColor: "Show Status as Color",
  hideStatusColor: "Hide Status as Color",
  addToHangar: "Add to Hangar",
  resetFilter: "Reset Filter",
  resetPrices: "Reset Prices",
  requestPassword: "Request Password email",
  changePassword: "Change Password",
  updatePassword: "Update Password",
  copyBackupCodes: "Copy Backup Codes",
  add: "Add",
  addToWishlist: "Add to Wishlist",
  addAnother: "Add another",
  addVehicle: "Add Ships",
  addGroup: "Add Group",
  addModule: "Add another Module",
  addUpgrade: "Add another Upgrade",
  showTableView: "Display as List",
  showGridView: "Display as Grid",
  syncRsiHangar: "Sync RSI Hangar",
  compare: { models: "Compare" },
  hangar: {
    editGroups: "Add or remove Groups",
    editName: "Name your Ship",
    useName: "Use Name",
    destroyAll: "Remove all Ships",
    showOnPublicHangar: "Make Public",
    hideFromPublicHangar: "Hide from Public",
    editGroupsSelected: "Add/Remove from Groups",
    resetIngame: {
      openModal: "Reset Ingame Ships after Wipe",
      moveToWishlist: "Move to Wishlist",
      removeAll: "Remove all",
    },
  },
  syncExtension: {
    cancel: "Cancel",
    start: "Start Sync",
    close: "Close",
  },
  empty: {
    reset: "Reset Filters/Pages",
    resetPage: "Go to First Page",
    hangarGuide: "Need Help?",
  },
  fleet: {
    join: "Join %{fleet}!",
    leave: "Leave %{fleet}",
    createInviteUrl: "Create Invite Urls",
    inviteMember: "Invite new Member",
    acceptInvite: "Accept Invitation",
    declineInvite: "Decline Invitation",
    acceptRequest: "accept Request",
    declineRequest: "Decline Request",
    showOwners: "More",
    members: {
      invite: "Invite",
      promote: "Promote",
      demote: "Demote",
      accept: "Accept",
      decline: "Decline",
      remove: "Remove",
    },
    inviteUrls: { create: "Create new Invite Url" },
  },
  model: {
    gallery: "Gallery",
    onSale: "On Sale: %{price}",
    store: "RSI Page",
  },
  unselect: "Unselect all Selected",
  openPriceModal: "Submit Price",
  shoppingCart: {
    add: "Add to Cart",
    refresh: "Refresh Cart",
    clear: "Clear Cart",
  },
  twoFactor: {
    enable: "Enable Two-factor",
    disable: "Disable Two-factor",
    generateBackupCodes: "Generate Backup Codes",
  },
  holoViewer: {
    autoRotate: {
      enable: "Enable auto rotate",
      disable: "Disable auto rotate",
    },
    zoom: {
      enable: "Enable zoom",
      disable: "Disable zoom",
    },
    color: {
      enable: "Enable colors",
      disable: "Disable colors",
    },
  },
  roadmap: {
    showRemoved: "Show Removed",
    hideRemoved: "Hide Removed",
  },
};
