export default {
  welcome: "Bienvenue sur",
  welcomeShips: "Derniers vaisseaux",
  welcomeImages: "Images Aléatoires",
  models: {
    index: "Index des vaisseaux",
    fleetchart: "Fleetchart des vaisseaux",
  },
  images: "Index des images",
  stats: "Statistiques",
  error: "Erreur de navigation",
  comments: "Commentaires",
  login: "Se connecter",
  hangar: {
    index: "Mon Hangar",
    preview: {
      h1: "Aperçu de mon Hangar",
      h2: "Votre Hangar personnel nécessite un compte FleetYards.net",
      h3: "Quelles sont les fonctionnalités du Hangar ?",
    },
    alternativeNames: "Noms alternatifs",
    stats: "Mes statistiques de Hangar",
    public: "Hangar de %{user}",
    import: "Importer",
    wishlist: "Liste de souhaits",
    publicWishlist: "Liste de souhaits de %{user}",
    resetIngame: "Réinitialiser les vaisseaux obtenus dans le jeu",
  },
  syncExtension: "Sync RSI Hangar",
  editMyVehicle: "Éditer mon %{vehicle}",
  nameMyVehicle: "Nommer mon %{vehicle}",
  editGroups: "Editer les groupes",
  newVehicles: "Ajouter des vaisseaux",
  myVehicleAddons: "Ajouter des modules / mises à jour pour %{vehicle}",
  addToHangar: "Ajoutez «%{model}» à votre Hangar",
  impressum: "Impressum",
  privacy: "Politique de confidentialité",
  cookie: "Politique relative aux cookies",
  requestPassword: "Demander un mot de passe",
  changePassword: "Modifier le mot de passe",
  confirmAccess: "Confirmer l'accès",
  filterModels: "Filtrer les vaisseaux",
  filterVehicles: "Filtrer Hangar",
  filterCargoRoutes: "Filtrer les routes de fret",
  filterCommodities: "Filtrer les marchandises",
  fleets: {
    owners: "Propriétaires",
    add: "Créer une nouvelle Flotte",
    invites: "Invitations à la flotte",
    inviteUrls: "Liens d'invitation",
    members: "Membres",
    stats: "Statistiques",
    preview: {
      h1: "Aperçu de la Flotte",
      h2: "Pour créer une Flotte, vous devez avoir un compte FleetYards.net",
      h3: "Quelles sont les fonctionnalités de la Flotte ?",
    },
    settings: {
      fleet: "Paramètres de la Flotte",
      membership: "Paramètres des membres",
    },
    inviteMember: "Inviter un nouveau membre",
  },
  empty: "Il n'y a rien à voir ici...",
  hangarGroup: {
    create: "Créer un groupe",
    edit: "Modifier le groupe",
  },
  searchHistory: "Historique de recherche",
  compare: { models: "Comparer les vaisseaux" },
  settings: {
    account: "Mon compte",
    profile: "Paramètres du profil",
    hangar: "Paramètres de Hangar",
    notifications: "Paramètres de notification",
    verify: "Vérifier votre compte RSI",
    security: {
      index: "Sécurité du Compte",
      twoFactor: "Authentification à deux facteurs",
    },
    twoFactor: {
      enable: "Activer l'authentification à deux facteurs",
      disable: "Désactiver l'authentification en deux étapes",
      backupCodes: "Générer des codes de sauvegarde",
    },
  },
  roadmap: "Roadmap des vaisseaux",
  tools: {
    profitCalculator: { index: "Calculatrice de profits" },
    tradeRoutes: {
      index: "Routes commerciales",
      withShip: "Routes commerciales pour %{name} (%{cargo})",
    },
  },
  starsystems: "Systèmes planétaires",
  starsystem: "Le système %{starsystem}",
  celestialObjects: "Objets célestes",
  celestialObject: "%{celestialObject} dans le système %{starsystem}",
  moons: "Lunes",
  stations: "Stations",
  station: "%{station} à proximité de %{celestialObject}",
  stationImages: "%{station} à proximité de %{celestialObject}",
  shops: "Points de vente",
  shop: "%{shop} sur %{station}",
  commodities: "Marchandises",
  rsiVerification: "Vérifier votre compte RSI",
  hangarGuideEmpty: "Rien à voir ici.",
  hangarGuide: "Guide du Hangar",
  support: "Soutenir FleetYards.net",
  vehicle: { bulkGroupEdit: "Ajouter/Retirer des groupes" },
  modals: {
    shopCommodity: {
      update: "Mettre à jour la marchandise %{shopCommodity}",
      create: "Ajouter une marchandise",
      sellPrices: "Prix de vente de %{shopCommodity}",
      buyPrices: "%{shopCommodity} Prix d'achat",
      rentalPrices: "Prix de vente de %{shopCommodity}",
    },
    commodityPrice: { create: "Soumettre le prix" },
  },
  shoppingCart: "Panier",
};
