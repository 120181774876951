export default {
  showReleased: "Alte Versionen anzeigen",
  hideReleased: "Alte Versionen verbergen",
  upgrade: "Update verfügbar!",
  backToHome: "Neuen Kurs planen",
  disableGrouping: "Gruppierung deaktivieren",
  enableGrouping: "Gruppierung aktivieren",
  groupedByModel: "Nach Modell gruppieren",
  ungrouped: "Ungruppiert",
  destroyAccount: "Konto löschen",
  saveScreenshot: "Als PNG speichern",
  verify: "Bestätigen",
  findModel: "Type to find a Ship",
  find: "Suchbegriff eintippen",
  clearHistory: "Verlauf löschen",
  sortBy: "Sortieren nach",
  back: "Zurück",
  open: "Öffnen",
  copy: "Kopieren",
  more: "Mehr...",
  showMore: "Mehr anzeigen",
  export: "Export",
  import: "Import",
  here: "hier",
  backToTop: "Zurück zum Anfang",
  cancel: "Abbrechen",
  loading: "Bitte warten...",
  ok: "Ok",
  reset: "Reset",
  reload: "Neu Laden",
  confirm: "Bestätigen",
  moreInfo: "Weitere Informationen",
  showDetailPage: "Detailseite",
  showFilter: "Filter anzeigen",
  hideFilter: "Filter ausblenden",
  showDetails: "Details anzeigen",
  hideDetails: "Details verbergen",
  showGuide: "Guide anzeigen",
  hideGuide: "Guide verbergen",
  showFleetchart: "Flottenübersicht",
  hideFleetchart: "Standardansicht",
  showLabels: "Labels anzeigen",
  hideLabels: "Labels verbergen",
  resetZoom: "Zoom zurücksetzen",
  supportUs: "FleetYards.net unterstützen",
  login: "Anmelden",
  signUp: "Registrieren",
  confirmAccess: "Passwort bestätigen",
  "reset-password": "Passwort vergessen?",
  edit: "Bearbeiten",
  done: "Fertig",
  share: "Teilen",
  change: "Ändern",
  upload: "Hochladen",
  editCommodities: "Waren bearbeiten",
  save: "Speichern",
  remove: "Entfernen",
  delete: "Löschen",
  deleteSelected: "Ausgewählte löschen",
  close: "Schließen",
  filter: "Filter",
  showStatusColor: "Status als Farbe anzeigen",
  hideStatusColor: "Status als Farbe ausblenden",
  addToHangar: "Add to your Hangar",
  resetFilter: "Filter zurücksetzen",
  resetPrices: "Preise zurücksetzen",
  requestPassword: "Passwort-E-Mail anfordern",
  changePassword: "Passwort ändern",
  updatePassword: "Passwort aktualisieren",
  copyBackupCodes: "Backup-Codes kopieren",
  add: "Hinzufügen",
  addToWishlist: "Add to Wishlist",
  addAnother: "Weitere hinzufügen",
  addVehicle: "Schiffe hinzufügen",
  addGroup: "Gruppe hinzufügen",
  addModule: "Neues Modul hinzufügen",
  addUpgrade: "Neues Upgrade hinzufügen",
  showTableView: "Als Liste anzeigen",
  showGridView: "Als Raster anzeigen",
  syncRsiHangar: "Sync RSI Hangar",
  compare: { models: "Vergleichen" },
  hangar: {
    editGroups: "Gruppen hinzufügen oder löschen",
    editName: "Benenne dein Schiff",
    useName: "Name verwenden",
    destroyAll: "Alle Schiffe entfernen",
    showOnPublicHangar: "Veröffentlichen",
    hideFromPublicHangar: "Nicht öffentlich anzeigen",
    editGroupsSelected: "Hinzufügen/Entfernen aus Gruppen",
    resetIngame: {
      openModal: "Reset Ingame Ships after Wipe",
      moveToWishlist: "Move to Wishlist",
      removeAll: "Remove all",
    },
  },
  syncExtension: {
    cancel: "Cancel",
    start: "Start Sync",
    close: "Close",
  },
  empty: {
    reset: "Filter/Seiten zurücksetzen",
    resetPage: "Zur ersten Seite",
    hangarGuide: "Need Help?",
  },
  fleet: {
    join: "%{fleet} beitreten!",
    leave: "%{fleet} verlassen",
    createInviteUrl: "Einladungs-Urls erstellen",
    inviteMember: "Neues Mitglied einladen",
    acceptInvite: "Einladung annehmen",
    declineInvite: "Einladung ablehnen",
    acceptRequest: "Anfrage akzeptieren",
    declineRequest: "Anfrage ablehnen",
    showOwners: "Mehr...",
    members: {
      invite: "Einladen",
      promote: "Befördern",
      demote: "Degradieren",
      accept: "Akzeptieren",
      decline: "Ablehnen",
      remove: "Entfernen",
    },
    inviteUrls: { create: "Neue Einladungs-URL erstellen" },
  },
  model: {
    gallery: "Galerie",
    onSale: "Im Verkauf: %{price}",
    store: "RSI-Seite",
  },
  unselect: "Auswahl aufheben",
  openPriceModal: "Preis einreichen",
  shoppingCart: {
    add: "In den Warenkorb",
    refresh: "Warenkorb aktualisieren",
    clear: "Warenkorb leeren",
  },
  twoFactor: {
    enable: "Zwei-Faktor aktivieren",
    disable: "Zwei-Faktor deaktivieren",
    generateBackupCodes: "Backup-Codes generieren",
  },
  holoViewer: {
    autoRotate: {
      enable: "Auto-Drehung aktivieren",
      disable: "Auto-Drehung deaktivieren",
    },
    zoom: {
      enable: "Zoom aktivieren",
      disable: "Zoom deaktivieren",
    },
    color: {
      enable: "Farben aktivieren",
      disable: "Farben deaktivieren",
    },
  },
  roadmap: {
    showRemoved: "Entfernte anzeigen",
    hideRemoved: "Entfernte Einträge ausblenden",
  },
};
