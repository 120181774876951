export default {
  true: "Oui",
  false: "Non",
  null: "nul",
  and: "et",
  hide: "Masquer",
  show: "Afficher",
  only: "Uniquement",
  description: "Description",
  unavailable: "indisponible",
  selected: "Sélectionné",
  actions: "Actions",
  enabled: "Activé",
  disabled: "Désactivé",
  collapse: "Réduire",
  price: "Prix",
  expand: "Agrandir",
  anonymous: "Anonymous",
  toggleNavigation: "Changer de navigation",
  enableYoutube:
    "Cliquez pour activer les liens Youtube ou cliquez avec le bouton droit de la souris pour copier l'URL de la vidéo",
  or: "ou",
  deselect: "cliquer pour désélectionner",
  networkStatusOffline: "statut du serveur : hors-ligne - service indisponible",
  groups: "Groupes",
  fleetInviteToken: "invitation de la flotte",
  scDataVersion: "Version corrigée",
  home: { search: "Recherche de vaisseaux" },
  shop: {
    rentalPrice: "prix de location",
    sellPrice: "prix de vente",
    buyPrice: "prix d'achat",
  },
  shopCommodity: {
    item: "Objets",
    shop: "Magasin",
    sellPrices: "Prix de vente",
    buyPrices: "Prix d'achat",
    rentalPrices: "Prix de location",
    confirmed: "Confirmé ",
    submittedBy: "Soumis par",
    prices: {
      label: "prix",
      rentalPrice: "prix de location",
      averageRentalPrice: "location (Ø)",
      sellPrice: "prix de vente",
      averageSellPrice: "vente pour (Ø)",
      buyPrice: "Achats pour",
      averageBuyPrice: "achats pour (Ø)",
      rentalPrice1Day: "1 jour pour",
      averageRentalPrice1Day: "1 jour pour (Ø)",
      rentalPrice3Days: "3 jours pour",
      averageRentalPrice3Days: "3 jours pour (Ø)",
      rentalPrice7Days: "7 jours pour",
      averageRentalPrice7Days: "7 jours pour (Ø)",
      rentalPrice30Days: "30 jours pour",
      averageRentalPrice30Days: "30 jours pour (Ø)",
    },
  },
  stats: {
    quickStats: {
      newShips: "Nouveaux vaisseaux en %{year}",
      totalShips: "Nombre total de vaisseaux",
      totalMembers: "Nombre total de membres",
    },
    modelsByClassification: "Vaisseaux par catégorie",
    modelsBySize: "Vaisseaux par taille",
    vehiclesByModel: "Vaisseau par modèle (Top %{limit})",
    modelsPerMonth: "Vaisseaux par mois",
    modelsByManufacturer: "Vaisseaux par constructeur",
    modelsByProductionStatus: "Vaisseau par état de production",
  },
  roadmap: {
    selectWeek: "Sélectionnez une carte",
    shipRoadmap: "Carte du vaisseau",
    releases: "toutes les publications",
    unscheduled: "Non planifiées",
    lastUpdate: "Dernière mise à jour",
    released: "publiée",
    recentlyUpdated: "Mis à jour récemment",
    committed: "enclenchée",
    lastVersion: {
      active: {
        decreased: "L'élément a été retiré de la roadmap",
        increased: "L'élément a été rajouté à la roadmap",
      },
      committed: "L'élément a été marqué comme engagé",
      released: "L'élément a été publié dans le PU",
      release: "Élément déplacé de la version %{old} à %{new}",
      addedToRelease: "Nouvel élément dans la version %{release}",
    },
    stories: {
      one: "%{count} stocké",
      other: "%{count} stockés",
    },
    tasks: {
      one: "de %{count} tâche",
      other: "de %{count} tâches",
    },
    ships: {
      one: "%{count} vaisseau",
      other: "%{count} Vaisseaux",
    },
  },
  charts: {
    view: {
      one: "%{label}:<br><b>%{count}</b> Vue",
      other: "%{label}:<br><b>%{count}</b> Vues",
    },
    ship: {
      one: "%{label}:<br><b>%{count}</b> Vaisseau",
      other: "%{label}:<br><b>%{count}</b> Vaisseaux",
    },
    "ship-pie": {
      one: "%{label}:<br><b>%{count}</b> Vaisseau (%{percentage} %)",
      other: "%{label}:<br><b>%{count}</b> Vaisseaux (%{percentage} %)",
    },
    "component-pie": {
      one: "%{label}:<br><b>%{count}</b> Composant (%{percentage} %)",
      other: "%{label}:<br><b>%{count}</b> Composants (%{percentage} %)",
    },
    user: {
      one: "%{label}:<br><b>%{count}</b> Utilisateur",
      other: "%{label}:<br><b>%{count}</b> Utilisateurs",
    },
    visit: {
      one: "%{label}:<br><b>%{count}</b> Visite",
      other: "%{label}:<br><b>%{count}</b> Visites",
    },
  },
  rsiRoadmap: "Carte RSI",
  unknown: "inconnu",
  none: "nul",
  search: {
    default: "Recherche",
    shareTitle: "Recherche pour: %{query}",
  },
  unknownModel: "%{slug} Inconnu",
  "not-available": "N/A",
  receiveSaleNotifications: "",
  publicUrl: "URL publique",
  name: "Nom",
  homepage: "Page principale",
  discord: "Serveur Discord",
  youtube: "Chaîne Youtube",
  twitch: "Chaîne Twitch",
  guilded: "Serveur Guilded",
  "3dView": "3D",
  poweredByStarship42: "Développé par starship42.com",
  exportStarship42: "Vu sur Starship42",
  filter: "Filtre",
  taxExcluded: "excl. VAT",
  hangar: "Hangar",
  public: "Publique",
  hangarStats: "Statistiques",
  wishlist: "Liste de souhait",
  fleetchart: "Graphique de la Flotte",
  fleetchartApp: {
    screenHeight: "Hauteur de l'écran",
    viewpoint: "Point de vue",
    screenHeightOptions: {
      "1x": "1x",
      "1_5x": "1.5x",
      "2x": "2x",
      "3x": "3x",
      "4x": "4x",
    },
    viewpointOptions: {
      side: "Côté",
      top: "Haut",
      angled: "En biais",
    },
    gridSize: "%{size} mètre par zone de grille",
    mode: "Mode",
    modeOptions: {
      classic: "Classique",
      panzoom: "v2",
    },
  },
  fleetStats: "Statistiques",
  hangarMetrics: {
    total: "Total",
    totalMoney: "Valeur de la monnaie",
    totalCredits: "Crédits",
    totalMinCrew: "Équipage minimum",
    totalMaxCrew: "Équipage maximum",
    totalCargo: "Cargaison totale",
  },
  selectModel: "Sélectionner un vaisseau",
  compare: {
    removeModel: "Enlever un vaisseau",
    addModel: { nullablePrompt: "Ajouter un vaisseau" },
    enough: "Ne pensez-vous pas que 8 vaisseaux suffisent ? ;)",
  },
  cargoRoutes: "Routes de cargaisons",
  uecPerUnit: "aUEC / Unité",
  au: "AU",
  uec: "aUEC",
  sell: "Vente",
  buy: "Achat",
  yourFlagship: "Votre vaisseau amiral",
  flagship: "Vaisseau amiral",
  fleet: {
    classes: "Flotte",
    fid: "Identifiant de la Flotte",
    name: "Nom",
    logo: "Logo",
    rsiSid: "SID",
    public: "Aperçu publique de la Flotte",
    ts: "Serveur chat de l'équipe",
    awaitingConfirmation: "En attente de confirmation par la Flotte",
    members: {
      primary: "Flotte principale?",
      hideShips: "Cacher vos Vaisseaux pour cette Flotte ?",
      shipsFilter: {
        label: "Quels vaisseaux doivent être ajoutés à la Flotte ?",
        nullablePrompt: "Aucune option sélectionnée",
        field: "Sélection des vaisseaux",
        values: {
          all: "Tous les Vaisseaux",
          hangar_group: "Tous les vaisseaux dans un Hangar de groupe donné",
          hide: "Pas de Vaisseaux",
        },
      },
      hangarGroupId: {
        field: "Hangar de groupe",
        label: "Spécifier un Hangar de groupe",
        nullablePrompt: "Aucun Groupe sélectionné",
      },
      total: "Total: %{count}",
      joined: "Inscrit le",
      invited: "Invité",
      requested: "Demande d'adhésion requise",
      declined: "Refusée",
      roles: {
        admin: "Admin",
        officer: "Officier",
        member: "Membre",
      },
    },
    inviteUrls: {
      noLimit: "Pas de limite",
      noExpiration: "N'expire jamais",
      usesLeft: "%{count} de jours restants",
      expiresIn: "Expire dans %{time}",
      expired: "Expirée",
      expiresAfterOptions: {
        infinite: "Jamais",
        "30_minutes": "30 minutes",
        "1_hour": "1 heure",
        "6_hours": "6 heures",
        "12_hours": "12 heures",
        "1_day": "1 jour",
        "7_days": "7 jours",
      },
      limitOptions: {
        infinite: "Pas de limite",
        1: "1 utilisée",
        5: "5 utilisées",
        10: "10 utilisées",
        25: "25 utilisées",
        50: "50 utilisées",
        100: "100 utilisées",
      },
    },
  },
  hangarGroup: {
    name: "Nom",
    color: "Couleur ",
    sort: "Ordre de Tri",
    public: "Afficher sur le hangar publique ?",
  },
  source: "Source",
  model: {
    variants: "Variantes",
    manufacturer: "Fabricant",
    loaners: "Prêts",
    paints: "Peintures",
    modules: "Modules",
    modulePackages: "Paquets de module",
    upgrades: "Kits d'Amélioration",
    brochure: "Brochure",
    onSale: "En Vente",
    salesPage: "Page des Ventes",
    addons: "Kits de Modules et d'Amélioration",
    productionStatus: {
      "in-production": "En cours de production",
      "in-concept": "En concept",
      ready: "Hangar prêt",
      "flight-ready": "Le vol est prêt",
      announced: "Annoncée",
    },
  },
  metrics: {
    info: "Information",
    levels: "Niveaux",
    topView: "Vue de dessus",
    base: "Base",
    crew: "Équipe",
    speed: "Vitesse",
    missileOptions: "Options de Missile (si disponible)",
  },
  component: { size: "Taille : %{size}" },
  hardpoint: {
    legend: {
      headline: "Légende",
      slotTaken: "Emplacement pris",
      slotAvailable: "Emplacement disponible",
    },
    size: "Taille",
    rackSize: "Taille de la rampe",
    details: "Détails",
    group: "Groupe",
    groups: {
      avionic: "Avioniques",
      system: "Systèmes",
      propulsion: "Propulsion",
      thruster: "Propulseur",
      weapon: "Armes",
    },
    types: {
      radar: "Radar",
      computers: "Ordinateurs",
      power_plants: "Générateurs",
      coolers: "Refroidisseurs",
      shield_generators: "Générateurs de bouclier",
      fuel_intakes: "Consommation de carburant",
      fuel_tanks: "Réservoirs de carburant",
      quantum_drives: "Moteurs quantiques",
      jump_modules: "Modules de Saut",
      quantum_fuel_tanks: "Réservoirs de carburant quantique",
      main_thrusters: "Propulseurs Principaux",
      maneuvering_thrusters: "Propulseurs de manœuvre",
      weapons: "Armes",
      turrets: "Tourelles",
      missiles: "Missiles",
      utility_items: "Objets Utilitaires",
      emp: "EMP",
      qed: "QED",
    },
  },
  blank: {
    propulsion: "Aucune propulsion présente",
    ordnance: "Aucune munition présente",
    modular: "Aucun module présent",
    avionics: "Aucun Avionique présent",
    fleetInvites: "Aucune invitation présente",
    shopCommodities: "Aucun produit disponible",
    shoppingCart: "Panier d'achat vide",
    userShips:
      "Votre Hangar est actuellement vide. Pour ajouter des Vaisseaux / Véhicules à votre Hangar, cliquez sur l'icône suivante sur n'importe quel vaisseau de la Base de données :",
  },
  user: {
    rsiHandle: "Pseudo",
    avatar: "Avatar",
    unconfirmedEmail: "Adresse email non confirmée",
    saleNotify: "Je veux recevoir les notifications de vente",
    publicHangar: "Hangar public activé",
    publicHangarLoaners:
      "Afficher le marqueur des prêteurs dans le Hangar Public",
    publicWishlist: "Public Wishlist enabled",
    hideOwner: "Hide Ownership for Fleets",
  },
  vehicle: {
    name: "Nom",
    serial: "Série",
    owner: "commandé par",
    flagship: "Vaisseau amiral",
    wanted: "Recherché",
    loaner: "Prêteur",
    public: "Visibilité publique",
    fullPublic: "Visibilité Publique incluant le nom",
    publicShort: "Publique",
    nameVisible: "Visibilité publique",
    saleNotify: "Je souhaite recevoir les notifications de vente",
    hangarGroups: "Groupes",
    selectGroup: "Sélectionner un Groupe",
    modelPaintSelect: { nullablePrompt: "Sélectionner une peinture" },
    bulkGroupEdit: { hint: "Les groupes existants seront remplacés !" },
    hasLoaners: "Prêteurs",
    boughtViaSelect: { label: "Acheté via" },
  },
  image: {
    background: "Utiliser comme arrière-plan ?",
    enabled: "Activé?",
    global: "Global?",
    delete: "Supprimer",
    start: "Démarrer",
    cancel: "Annuler",
    processing: "Traitement en cours...",
    error: "Erreur",
    name: "Nom",
    size: "Taille du fichier",
    dropzone: "Déposer les images ici",
    cancelUpload: "Annuler le téléchargement",
    startUpload: "Démarrer le téléchargement",
    selectImages: "Ajouter des images...",
    selectFolder: "Ajouter un dossier...",
  },
  signup: {
    link: "Vous n’avez pas de compte ?",
    privacyPolicy: "En créant un compte FleetYards, vous acceptez notre",
  },
  privacyPolicy: "Politique de Confidentialité",
  login: "Nom d'utilisateur ou e-mail",
  username: "Nom d’utilisateur",
  twoFactorCode: "Code d’authentification à deux facteurs",
  email: "Email",
  avatar: "Avatar",
  alreadyRegistered: "Vous avez déjà un compte?",
  currentPassword: "Mot de passe actuel",
  password: "Mot de passe",
  passwordConfirmation: "Confirmation du mot de passe",
  rememberMe: "Se souvenir de moi",
  emptyList: "Pas de %{name} présent",
  all: "Tout",
  supportUs: "Soutenez FleetYards.net",
  account: {
    destroyInfo:
      "Si vous voulez supprimer votre compte et enlever toutes vos informations de Fleetyards.net et ses Services cliquez sur le Bouton ci-dessous. Sachez qu'il s'agit d'une action permanente qui ne peut pas être révoquée.",
  },
  filters: {
    shops: {
      name: "Nom",
      type: "Type",
      celestialObject: "Objet Céleste",
      model: "Vaisseau",
      equipment: "Équipement",
      component: "Composant",
      commodity: "Marchandise",
      commodityType: "Type de marchandise",
      starsystem: "Système planétaire",
      station: "Station",
    },
    shopCommodities: {
      commodityType: "Type de marchandise",
      station: "Station",
      shopId: "Magasin",
      equipment: "Équipement",
      component: "Composant",
      componentItemTypeFilter: "Types d'éléments de composant",
      equipmentItemTypeFilter: "Types d'objet d'équipement",
      equipmentTypeFilter: "Type d'équipement",
      equipmentSlotFilter: "Emplacement d'équipement",
      commodity: "Marchandise",
      model: "Vaisseau",
      name: "Nom",
    },
    shopItems: {
      category: "Catégorie",
      subCategory: "Sous catégorie",
      name: "Nom",
      manufacturer: "Fabricant",
      minPrice: "Prix min.",
      maxPrice: "Prix max.",
    },
    stations: {
      name: "Nom",
      celestialObject: "Objet Céleste",
      starsystem: "Système planétaire",
      type: "Type de Station",
      shops: "Magasins",
      docks: "Quais",
      habs: "Habitation?",
      cargoHub: "plate-forme de fret",
      refinery: "Station de raffinerie?",
    },
    vehicles: {
      name: "Nom ou nom du vaisseau",
      lengthLt: "Longueur <=",
      lengthGt: "Longueur >=",
      pledgePriceLt: "Prix du Pledge <=",
      pledgePriceGt: "Prix du Pledge >=",
      priceLt: "Prix <=",
      priceGt: "Prix >=",
      wanted: "Recherché",
      public: "Publique",
      group: "Groupe",
      loaner: "Prêteurs",
      boughtVia: { nullablePrompt: "Acheté via?" },
    },
    tradeRoutes: {
      sortBy: "Trier par",
      sortByValues: {
        percent: "Pourcentage Max.",
        profit: "Bénéfice max.",
      },
      cargoShip: "Avec le vaisseau de marchandise ?",
      origin: "De ?",
      destination: "Vers?",
      excludeCommodityType: "Exclure le type de marchandise",
      originCelestialObject: "Lieu d'origine d'objet céleste",
      destinationCelestialObject: "Destination d'objet céleste",
      originStarsystem: "Système planétaire d'origine",
      destinationStarsystem: "Destination du système planétaire",
    },
    models: {
      shipRole: "Rôle du vaisseau",
      classification: "Classification",
      focus: "Focus",
      manufacturer: "Fabricant",
      onSale: "En Vente",
      size: "Taille",
      price: "Prix",
      pledgePrice: "Prix du Pledge",
      productionStatus: "Statut de la production",
      lengthLt: "Longueur <=",
      lengthGt: "Longueur >=",
      beamLt: "Rayon <=",
      beamGt: "Rayon >=",
      heightLt: "Hauteur <=",
      heightGt: "Hauteur >=",
      pledgePriceLt: "Prix du Pledge <=",
      pledgePriceGt: "Prix du Pledge >=",
      priceLt: "Prix <=",
      priceGt: "Prix >=",
      willItFit: "Est-ce que cela conviendra ?",
    },
    images: {
      station: "-",
      model: "Vaisseau",
    },
    fleets: {
      member: "Membre",
      members: { role: "Rôle" },
      inviteUrls: {
        expiresAfter: "Expire après",
        limit: "Nombre maximum d’utilisations",
      },
    },
  },
  station: {
    docks: "Quais",
    location: "Localisation",
    type: "Type",
    habitation: "Habitation",
    habs: "Habitat",
    shops: "Magasins",
    locationPrefix: {
      default: "Activé",
      asteriod: "à proximité d'un astéroïde",
      orbit: "autour de l'orbite",
    },
    locationSuffix: "à proximité de %{location}",
  },
  tradeRoutes: {
    buy: "Acheter pour: %{uec}",
    sell: "Vendre pour: %{uec}",
    cargoShip: "pour %{cargo} SCU",
    sortByPercent: "Trier par Pourcentage",
    sortByProfit: "Trier par Bénéfice",
    sortByStation: "Trier par Station",
    showAveragePrices: "Affichage des prix moyens",
    showLatestPrices: "Affichage des derniers prix",
  },
  erkul: {
    prefix: "Essayer la configuration avec",
    link: "Calculatrice de DPS d'Erkul",
  },
  scunpacked: {
    prefix: "Configurations basées sur les produits développés par Gamefiles ",
    link: "SCunpacked",
  },
  filterGroup: {
    nullablePrompt: "Aucune option sélectionnée",
    prompt: "Merci de sélectionner une option",
  },
  newVehicle: { nullablePrompt: "Sélectionner un vaisseau" },
  addAddon: { nullablePrompt: "" },
  pagination: {
    perPage: "Par Page",
    pages: "%{page} sur %{total}",
  },
  table: { selected: "%{count} sélectionné" },
  commodityPrice: {
    shopId: "Magasin",
    station: "Station",
    commodityItemType: "Type de marchandise",
    commodityItemId: "Article de marchandise",
    item: "Magasins de marchandise",
    price: "Prix",
    type: "Type",
    shop: "Magasin",
    submittedBy: "Soumis par",
  },
  shoppingCart: {
    total: "Total",
    itemTotal: "Total d'article",
    perItem: "par article",
    items: {
      one: "<b>%{count}</b> Article pour <b>%{price}</b>",
      other: "<b>%{count}</b> Articles pour <b>%{price}</b>",
    },
  },
  syncExtension: {
    status: {
      started: "Import Started...",
      finished: "Import Finished",
      failed: "Import Failed!",
    },
    processSteps: {
      fetchHangar: "Fetching Data from the RSI Hangar",
      submitData: "Submitting Data to the Fleetyards.net Backend",
    },
    sessionStatus: "RSI Session status",
    checkIdentity: "Check RSI Session",
    identityStatus: {
      pending: "Checking...",
      notFound: "not found",
      connected: "connected",
    },
    platforms: {
      chrome: "Google Chrome",
      firefox: "Mozilla Firefox",
      edge: "Microsoft Edge",
      opera: "Opera",
      github: "GitHub",
    },
    pledgeItems: {
      pages: "Pages",
      all: "All Pledge Items",
      ships: "Ships",
      components: "Modules",
      skins: "Paints",
    },
    importedItems: {
      new: "New",
      updated: "Updated",
      movedToWanted: "Moved to Wishlist",
      missing: "Unable to Import",
    },
  },
};
