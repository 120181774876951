export default {
  name: "Nome",
  description: "Descrizione",
  login: "Username o e-mail",
  username: "Username",
  twoFactorCode: "Codice due-fattori",
  email: "E-mail",
  currentPassword: "Password Attuale",
  password: "Password",
  passwordConfirmation: "Conferma Password",
  homepage: "Homepage",
  discord: "Server Discord",
  youtube: "Canale YouTube",
  twitch: "Canale Twitch",
  guilded: "Server Guilded",
  color: "Colore",
  fleetInviteToken: "Inviti Alla Flotta",
  quicksearch: {
    models: "Avenger, Carrack, Cutlass...",
    "models-fleetchart": "Avenger, Carrack, Cutlass...",
    hangar: "Enterprise, Andromeda...",
    "hangar-fleetchart": "Enterprise, Andromeda...",
    "hangar-wishlist": "Avenger, Carrack, Cutlass...",
    stations: "Cerca Stazioni...",
    shops: "Search for Shops...",
  },
  search: {
    default: "Cerca nel Verse...",
    "roadmap-progress-tracker": "Cerca",
  },
  home: { search: "Cerca Navi..." },
  fleet: {
    fid: "ID Flotta",
    rsiSid: "RSI SID",
    ts: "Server Teamspeak",
  },
  user: { rsiHandle: "RSI Handle" },
  vehicle: { serial: "Seriale" },
  commodityPrice: { price: "100.00" },
  filters: {
    shops: {
      name: "Casaba, Platinum Bay...",
      commodityName: "Aurora, L86...",
    },
    stations: { name: "Port Olisar, Teasa..." },
    shopItems: {
      name: "Aurora, L86...",
      minPrice: "Prezzo minimo in UEC",
      maxPrice: "Prezzo massimo in UEC",
    },
    vehicles: {
      name: "Enterprise, Andromeda...",
      priceLt: "Prezzo <=",
      priceGt: "Prezzo >=",
    },
    models: {
      name: "Gladius, Andromeda...",
      shipRole: "Cargo, Capital Ship, Fighter...",
      classification: "Vehicle, Fighter, Cargo...",
      manufacturer: "Origin, Robert Space Industries, Aegis...",
      onSale: "Sì o No",
      productionStatus: "Announced, Flight Ready, In Concept...",
    },
    fleets: { members: { username: "Username" } },
    shopCommodities: { name: "Nome" },
  },
};
