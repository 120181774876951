export default {
  welcome: "Welcome to",
  welcomeShips: "Latest Ships",
  welcomeImages: "Random Images",
  models: {
    index: "Ships Index",
    fleetchart: "Ships Fleetchart",
  },
  images: "Images Index",
  stats: "Stats",
  error: "Navigation Error",
  comments: "Comments",
  login: "Login",
  hangar: {
    index: "My Hangar",
    preview: {
      h1: "My Hangar Preview",
      h2: "Your personal Hangar requires an FleetYards.net Account",
      h3: "What Features does the Hangar include?",
    },
    alternativeNames: "Alternative Names",
    stats: "My Hangar Stats",
    public: "%{user} Hangar",
    import: "Import",
    wishlist: "Wishlist",
    publicWishlist: "%{user} Wishlist",
    resetIngame: "Reset Ingame Ships after Wipe",
  },
  syncExtension: "Sync RSI Hangar",
  editMyVehicle: "Edit my %{vehicle}",
  nameMyVehicle: "Name my %{vehicle}",
  editGroups: "Edit Groups",
  newVehicles: "Add Ships",
  myVehicleAddons: "Add Modules / Upgrades for %{vehicle}",
  addToHangar: 'Add "%{model}" to your Hangar',
  impressum: "Impressum",
  privacy: "Privacy policy",
  cookie: "Cookie policy",
  requestPassword: "Request Password",
  changePassword: "Change Password",
  confirmAccess: "Confirm access",
  filterModels: "Filter Ships",
  filterVehicles: "Filter Hangar",
  filterCargoRoutes: "Filter Cargo routes",
  filterCommodities: "Filter Commodities",
  fleets: {
    owners: "Owners",
    add: "Create a new Fleet",
    invites: "Fleet Invites",
    inviteUrls: "Invite Urls",
    members: "Members",
    stats: "Stats",
    preview: {
      h1: "Fleet Preview",
      h2: "To create a Fleet you need to have a FleetYards.net Account",
      h3: "What Features does your Fleet include?",
    },
    settings: {
      fleet: "Fleet Settings",
      membership: "Membership Settings",
    },
    inviteMember: "Invite new Member",
  },
  empty: "There's nothing to see here...",
  hangarGroup: {
    create: "Create Group",
    edit: "Edit Group",
  },
  searchHistory: "Search History",
  compare: {
    models: "Compare Ships",
  },
  settings: {
    account: "Account",
    profile: "Profile Settings",
    hangar: "Hangar Settings",
    notifications: "Notification Settings",
    verify: "Verify your RSI Account",
    security: {
      index: "Account Security",
      twoFactor: "Two-factor Authentiation",
    },
    twoFactor: {
      enable: "Enable Two-factor Authentiation",
      disable: "Disable Two-factor Authentiation",
      backupCodes: "Generate Two-Factor Backup Codes",
    },
  },
  roadmap: "Ship-Roadmap",
  tools: {
    profitCalculator: {
      index: "Profit Calculator",
    },
    tradeRoutes: {
      index: "Trade Routes",
      withShip: "Trade Routes for %{name} (%{cargo})",
    },
  },
  starsystems: "Starsystems",
  starsystem: "%{starsystem} Starsystem",
  celestialObjects: "Celestial Objects",
  celestialObject: "%{celestialObject} in the %{starsystem} System",
  moons: "Moons",
  stations: "Stations",
  station: "%{station} near %{celestialObject}",
  stationImages: "Images of %{station} near %{celestialObject}",
  shops: "Shops",
  shop: "%{shop} on %{station}",
  commodities: "Commodities",
  rsiVerification: "Verify your RSI-Account",
  hangarGuideEmpty: "Not much to see here?",
  hangarGuide: "Hangar Guide",
  support: "Support FleetYards.net",
  vehicle: {
    bulkGroupEdit: "Add/Remove Groups",
  },
  modals: {
    shopCommodity: {
      update: "Update Commodity %{shopCommodity}",
      create: "Add Commodity",
      sellPrices: "%{shopCommodity} Sell Prices",
      buyPrices: "%{shopCommodity} Buy Prices",
      rentalPrices: "%{shopCommodity} Rental Prices",
    },
    commodityPrice: {
      create: "Submit Price",
    },
  },
  shoppingCart: "Shopping Cart",
};
