export default {
  true: "Yes",
  false: "No",
  null: "-",
  and: "and",
  hide: "Hide",
  show: "Show",
  only: "Only",
  description: "Description",
  unavailable: "unavailable",
  selected: "Selected",
  actions: "Actions",
  enabled: "Enabled",
  disabled: "Disabled",
  collapse: "Collapse",
  price: "Price",
  expand: "Expand",
  anonymous: "Anonymous",
  toggleNavigation: "Toggle Navigation",
  enableYoutube:
    "Click to enable Youtube embeds or right click to copy Video URL",
  or: "or",
  deselect: "Click to deselect",
  networkStatusOffline: "Network Status: Offline - Service Unavailable",
  groups: "Groups",
  fleetInviteToken: "Fleet Invite",
  scDataVersion: "Patch Version",
  home: { search: "Search for Ships..." },
  shop: {
    rentalPrice: "rental",
    sellPrice: "sells for",
    buyPrice: "buys for",
  },
  shopCommodity: {
    item: "Item",
    shop: "Shop",
    sellPrices: "Sell Prices",
    buyPrices: "Buy Prices",
    rentalPrices: "Rental Prices",
    confirmed: "Confirmed?",
    submittedBy: "Submitted By",
    prices: {
      label: "Prices",
      rentalPrice: "rental",
      averageRentalPrice: "rental (Ø)",
      sellPrice: "sells for",
      averageSellPrice: "sells for (Ø)",
      buyPrice: "buys for",
      averageBuyPrice: "buys for (Ø)",
      rentalPrice1Day: "1 Day for",
      averageRentalPrice1Day: "1 Day for (Ø)",
      rentalPrice3Days: "3 Days for",
      averageRentalPrice3Days: "3 Days for (Ø)",
      rentalPrice7Days: "7 Days for",
      averageRentalPrice7Days: "7 Days for (Ø)",
      rentalPrice30Days: "30 Days for",
      averageRentalPrice30Days: "30 Days for (Ø)",
    },
  },
  stats: {
    quickStats: {
      newShips: "New Ships in %{year}",
      totalShips: "Total Ships",
      totalMembers: "Total Members",
    },
    modelsByClassification: "Ships by Classification",
    modelsBySize: "Ships by Size",
    vehiclesByModel: "Ships by Model (Top %{limit})",
    modelsPerMonth: "Ships per Month",
    modelsByManufacturer: "Ships by Manufacturer",
    modelsByProductionStatus: "Ships by Production Status",
  },
  roadmap: {
    selectWeek: "Select Week",
    shipRoadmap: "Ship-Roadmap",
    releases: "Releases",
    unscheduled: "Unscheduled",
    lastUpdate: "Last Update",
    released: "Released",
    recentlyUpdated: "Recently Updated",
    committed: "Committed",
    lastVersion: {
      active: {
        decreased: "Item was removed from the Roadmap",
        increased: "Item was readded to the Roadmap",
      },
      committed: "Item was marked as committed",
      released: "Item was released to PU",
      release: "Item moved from release %{old} to %{new}",
      addedToRelease: "Item new in release %{release}",
    },
    stories: {
      one: "%{count} Story",
      other: "%{count} Stories",
    },
    tasks: {
      one: "of %{count} Task",
      other: "of %{count} Tasks",
    },
    ships: {
      one: "%{count} Ship",
      other: "%{count} Ships",
    },
  },
  charts: {
    view: {
      one: "%{label}:<br><b>%{count}</b> View",
      other: "%{label}:<br><b>%{count}</b> Views",
    },
    ship: {
      one: "%{label}:<br><b>%{count}</b> Ship",
      other: "%{label}:<br><b>%{count}</b> Ships",
    },
    "ship-pie": {
      one: "%{label}:<br><b>%{count}</b> Ship (%{percentage} %)",
      other: "%{label}:<br><b>%{count}</b> Ships (%{percentage} %)",
    },
    "component-pie": {
      one: "%{label}:<br><b>%{count}</b> Component (%{percentage} %)",
      other: "%{label}:<br><b>%{count}</b> Components (%{percentage} %)",
    },
    user: {
      one: "%{label}:<br><b>%{count}</b> User",
      other: "%{label}:<br><b>%{count}</b> Users",
    },
    visit: {
      one: "%{label}:<br><b>%{count}</b> Visit",
      other: "%{label}:<br><b>%{count}</b> Visits",
    },
  },
  rsiRoadmap: "RSI Roadmap",
  unknown: "Unknown",
  none: "-",
  search: {
    default: "Search",
    shareTitle: "Search for: %{query}",
  },
  unknownModel: "%{slug} Unknown",
  "not-available": "N/A",
  receiveSaleNotifications: "",
  publicUrl: "Public URL",
  name: "Name",
  homepage: "Homepage",
  discord: "Discord Server",
  youtube: "Youtube Channel",
  twitch: "Twitch Channel",
  guilded: "Guilded Server",
  "3dView": "3D",
  poweredByStarship42: "Powered by starship42.com",
  exportStarship42: "View on Starship42",
  filter: "Filter",
  taxExcluded: "excl. VAT",
  hangar: "Hangar",
  public: "Public",
  hangarStats: "Stats",
  wishlist: "Wishlist",
  fleetchart: "Fleetchart",
  fleetchartApp: {
    screenHeight: "Screen Height",
    viewpoint: "Viewpoint",
    screenHeightOptions: {
      "1x": "1x",
      "1_5x": "1.5x",
      "2x": "2x",
      "3x": "3x",
      "4x": "4x",
    },
    viewpointOptions: {
      side: "Side",
      top: "Top",
      angled: "Angled",
    },
    gridSize: "%{size} meter per grid box",
    mode: "Mode",
    modeOptions: {
      classic: "Classic",
      panzoom: "v2",
    },
  },
  fleetStats: "Stats",
  hangarMetrics: {
    total: "Total",
    totalMoney: "Money Value",
    totalCredits: "Credits",
    totalMinCrew: "Min. Crew",
    totalMaxCrew: "Max. Crew",
    totalCargo: "Total Cargo",
  },
  selectModel: "Select a Ship",
  compare: {
    removeModel: "Remove Ship",
    addModel: { nullablePrompt: "Add a Ship" },
    enough: "Don't you think 8 Ships are enough? ;)",
  },
  cargoRoutes: "Cargo routes",
  uecPerUnit: "aUEC / Unit",
  au: "AU",
  uec: "aUEC",
  sell: "Sell",
  buy: "Buy",
  yourFlagship: "Your Flagship",
  flagship: "Flagship",
  fleet: {
    classes: "Fleet",
    fid: "Fleet ID",
    name: "Name",
    logo: "Logo",
    rsiSid: "SID",
    public: "Public Fleetview",
    ts: "Teamspeak Server",
    awaitingConfirmation: "Awaiting confirmation by Fleet",
    members: {
      primary: "Primary Fleet?",
      hideShips: "Hide your Ships for this Fleet?",
      shipsFilter: {
        label: "Which Ships should be added to the Fleet?",
        nullablePrompt: "No Option selected",
        field: "Ships Select",
        values: {
          all: "All Ships",
          hangar_group: "All Ships in a given Hangar Group",
          hide: "No Ships",
        },
      },
      hangarGroupId: {
        field: "Hangar Group",
        label: "Specify a Hangar Group",
        nullablePrompt: "No Group selected",
      },
      total: "Total: %{count}",
      joined: "Joined at",
      invited: "Invited",
      requested: "Membership Requested",
      declined: "Declined",
      roles: {
        admin: "Admin",
        officer: "Officer",
        member: "Member",
      },
    },
    inviteUrls: {
      noLimit: "No limit",
      noExpiration: "Never expires",
      usesLeft: "%{count} uses left",
      expiresIn: "Expires in %{time}",
      expired: "Expired",
      expiresAfterOptions: {
        infinite: "Never",
        "30_minutes": "30 minutes",
        "1_hour": "1 hour",
        "6_hours": "6 hours",
        "12_hours": "12 hours",
        "1_day": "1 day",
        "7_days": "7 days",
      },
      limitOptions: {
        infinite: "No limit",
        1: "1 use",
        5: "5 uses",
        10: "10 uses",
        25: "25 uses",
        50: "50 uses",
        100: "100 uses",
      },
    },
  },
  hangarGroup: {
    name: "Name",
    color: "Color",
    sort: "Sort Order",
    public: "Show on Public Hangar?",
  },
  source: "Source",
  model: {
    variants: "Variants",
    manufacturer: "Manufacturer",
    loaners: "Loaners",
    paints: "Paints",
    modules: "Modules",
    modulePackages: "Module Packages",
    upgrades: "Upgrade-Kits",
    brochure: "Brochure",
    onSale: "On Sale",
    salesPage: "Sales Page",
    addons: "Modules & Upgrade-Kits",
    productionStatus: {
      "in-production": "In Production",
      "in-concept": "In Concept",
      ready: "Hangar Ready",
      "flight-ready": "Flight Ready",
      announced: "Announced",
    },
  },
  metrics: {
    info: "Info",
    levels: "Levels",
    topView: "Top View",
    base: "Base",
    crew: "Crew",
    speed: "Speed",
    missileOptions: "Missle Options (if available)",
  },
  component: { size: "Size: %{size}" },
  hardpoint: {
    legend: {
      headline: "Legend",
      slotTaken: "Slot taken",
      slotAvailable: "Slot available",
    },
    size: "Size",
    rackSize: "Rack Size",
    details: "Details",
    group: "Group",
    groups: {
      avionic: "Avionics",
      system: "Systems",
      propulsion: "Propulsion",
      thruster: "Thruster",
      weapon: "Weapons",
    },
    types: {
      radar: "Radar",
      computers: "Computers",
      power_plants: "Power Plants",
      coolers: "Coolers",
      shield_generators: "Shield Generators",
      fuel_intakes: "Fuel Intakes",
      fuel_tanks: "Fuel Tanks",
      quantum_drives: "Quantum Drives",
      jump_modules: "Jump Modules",
      quantum_fuel_tanks: "Quantum Fuel Tanks",
      main_thrusters: "Main Thrusters",
      maneuvering_thrusters: "Maneuvering Thrusters",
      weapons: "Weapons",
      turrets: "Turrets",
      missiles: "Missiles",
      utility_items: "Utility Items",
      emp: "EMP",
      qed: "QED",
    },
  },
  blank: {
    propulsion: "No Propulsion present",
    ordnance: "No Ordnance present",
    modular: "No Modular present",
    avionics: "No Avionics present",
    fleetInvites: "No Invites present",
    shopCommodities: "No Commodites available",
    shoppingCart: "Shopping Cart empty",
    userShips:
      "Your Hangar is currently Empty. To Add Ships / Vehicles to Your Hangar click the following Icon on Any Ship in the Database:",
  },
  user: {
    rsiHandle: "Handle",
    avatar: "Avatar",
    unconfirmedEmail: "Unconfirmed email",
    saleNotify: "I want to receive Sale Notifications",
    publicHangar: "Public Hangar enabled",
    publicHangarLoaners: "Show Loaners Hint on Public Hangar",
    publicWishlist: "Public Wishlist enabled",
    hideOwner: "Hide Ownership for Fleets",
  },
  vehicle: {
    name: "Name",
    serial: "Serial",
    owner: "commanded by",
    flagship: "Flagship",
    wanted: "Wanted",
    loaner: "Loaner",
    public: "Public Visible",
    fullPublic: "Public Visible including Name",
    publicShort: "Public",
    nameVisible: "Public Visible",
    saleNotify: "I want to receive Sale Notifications",
    hangarGroups: "Groups",
    selectGroup: "Select a Group",
    modelPaintSelect: { nullablePrompt: "Select a Paint" },
    bulkGroupEdit: { hint: "Existing Groups will be overridden!" },
    hasLoaners: "Loaners",
    boughtViaSelect: { label: "Bought via" },
  },
  image: {
    background: "Use as Background?",
    enabled: "Enabled?",
    global: "Global?",
    delete: "Delete",
    start: "Start",
    cancel: "Cancel",
    processing: "Processing...",
    error: "Error",
    name: "Name",
    size: "File size",
    dropzone: "Drop images here",
    cancelUpload: "Cancel upload",
    startUpload: "Start upload",
    selectImages: "Add images...",
    selectFolder: "Add a folder...",
  },
  signup: {
    link: "Don't have an Account?",
    privacyPolicy: "By creating a FleetYards account, you agree to our",
  },
  privacyPolicy: "Privacy Policy",
  login: "Username or email",
  username: "Username",
  twoFactorCode: "Two-factor code",
  email: "Email",
  avatar: "Avatar",
  alreadyRegistered: "Already have an Account?",
  currentPassword: "Current Password",
  password: "Password",
  passwordConfirmation: "Password Confirmation",
  rememberMe: "Remember me",
  emptyList: "No %{name} present",
  all: "All",
  supportUs: "Support FleetYards.net",
  account: {
    destroyInfo:
      "If you want to delete Your Account and remove all your Information from Fleetyards.net and its Services click the Button below. Be aware of that this is a permanent Action and can't be reverted.",
  },
  filters: {
    shops: {
      name: "Name",
      type: "Type",
      celestialObject: "Celestial object",
      model: "Ship",
      equipment: "Equipment",
      component: "Component",
      commodity: "Commodity",
      commodityType: "Commodity Type",
      starsystem: "Starsystem",
      station: "Station",
    },
    shopCommodities: {
      commodityType: "Commodity Type",
      station: "Station",
      shopId: "Shop",
      equipment: "Equipment",
      component: "Component",
      componentItemTypeFilter: "Component Item Types",
      equipmentItemTypeFilter: "Equipment Item Types",
      equipmentTypeFilter: "Equipment Type",
      equipmentSlotFilter: "Equipment Slot",
      commodity: "Commodity",
      model: "Ship",
      name: "Name",
    },
    shopItems: {
      category: "Category",
      subCategory: "Sub Category",
      name: "Name",
      manufacturer: "Manufacturer",
      minPrice: "min. Price",
      maxPrice: "max. Price",
    },
    stations: {
      name: "Name",
      celestialObject: "Celestial Object",
      starsystem: "Starsystem",
      type: "Station Type",
      shops: "Shops",
      docks: "Docks",
      habs: "Habitation?",
      cargoHub: "Cargo Hub?",
      refinery: "Refinery Station?",
    },
    vehicles: {
      name: "Name or Ship name",
      lengthLt: "Length <=",
      lengthGt: "Length >=",
      pledgePriceLt: "Pledge Price <=",
      pledgePriceGt: "Pledge Price >=",
      priceLt: "Price <=",
      priceGt: "Price >=",
      wanted: "Wanted",
      public: "Public",
      group: "Group",
      loaner: "Loaners",
      boughtVia: { nullablePrompt: "Bought via?" },
    },
    tradeRoutes: {
      sortBy: "Sort by",
      sortByValues: {
        percent: "Max. Percent",
        profit: "Max. Profit",
      },
      cargoShip: "With Cargoship?",
      origin: "From?",
      destination: "To?",
      excludeCommodityType: "Exclude Commodity Type",
      originCelestialObject: "Origin Celestial Object",
      destinationCelestialObject: "Destination Celestial Object",
      originStarsystem: "Origin Starsystem",
      destinationStarsystem: "Destination Starsystem",
    },
    models: {
      shipRole: "Ship Role",
      classification: "Classification",
      focus: "Focus",
      manufacturer: "Manufacturer",
      onSale: "On Sale",
      size: "Size",
      price: "Price",
      pledgePrice: "Pledge Price",
      productionStatus: "Production Status",
      lengthLt: "Length <=",
      lengthGt: "Length >=",
      beamLt: "Beam <=",
      beamGt: "Beam >=",
      heightLt: "Height <=",
      heightGt: "Height >=",
      pledgePriceLt: "Pledge Price <=",
      pledgePriceGt: "Pledge Price >=",
      priceLt: "Price <=",
      priceGt: "Price >=",
      willItFit: "Will it Fit?",
    },
    images: {
      station: "Station",
      model: "Ship",
    },
    fleets: {
      member: "Member",
      members: { role: "Role" },
      inviteUrls: {
        expiresAfter: "Expire after",
        limit: "Max number of uses",
      },
    },
  },
  station: {
    docks: "Docks",
    location: "Location",
    type: "Type",
    habitation: "Habitation",
    habs: "Habs",
    shops: "Shops",
    locationPrefix: {
      default: "on",
      asteriod: "on asteroid near",
      orbit: "in orbit around",
    },
    locationSuffix: "near %{location}",
  },
  tradeRoutes: {
    buy: "Buy for: %{uec}",
    sell: "Sell for: %{uec}",
    cargoShip: "for %{cargo} SCU",
    sortByPercent: "Sort by Percent",
    sortByProfit: "Sort by Profit",
    sortByStation: "Sort by Station",
    showAveragePrices: "Display average Prices",
    showLatestPrices: "Display latest Prices",
  },
  erkul: {
    prefix: "Tryout Loadouts with",
    link: "Erkul's DPS Calculator",
  },
  scunpacked: {
    prefix: "Loadouts based on Gamefiles powered by",
    link: "SCunpacked",
  },
  filterGroup: {
    nullablePrompt: "No Option selected",
    prompt: "Please select an Option",
  },
  newVehicle: { nullablePrompt: "Select a Ship" },
  addAddon: { nullablePrompt: "" },
  pagination: {
    perPage: "Per Page",
    pages: "%{page} of %{total}",
  },
  table: { selected: "%{count} Selected" },
  commodityPrice: {
    shopId: "Shop",
    station: "Station",
    commodityItemType: "Commodity Type",
    commodityItemId: "Commodity Item",
    item: "ShopCommodity",
    price: "Price",
    type: "Type",
    shop: "Shop",
    submittedBy: "Submitted By",
  },
  shoppingCart: {
    total: "Total",
    itemTotal: "Item Total",
    perItem: "per Item",
    items: {
      one: "<b>%{count}</b> Item for <b>%{price}</b>",
      other: "<b>%{count}</b> Items for <b>%{price}</b>",
    },
  },
  syncExtension: {
    status: {
      started: "Import Started...",
      finished: "Import Finished",
      failed: "Import Failed!",
    },
    processSteps: {
      fetchHangar: "Fetching Data from the RSI Hangar",
      submitData: "Submitting Data to the Fleetyards.net Backend",
    },
    sessionStatus: "RSI Session status",
    checkIdentity: "Check RSI Session",
    identityStatus: {
      pending: "Checking...",
      notFound: "not found",
      connected: "connected",
    },
    platforms: {
      chrome: "Google Chrome",
      firefox: "Mozilla Firefox",
      edge: "Microsoft Edge",
      opera: "Opera",
      github: "GitHub",
    },
    pledgeItems: {
      pages: "Pages",
      all: "All Pledge Items",
      ships: "Ships",
      components: "Modules",
      skins: "Paints",
    },
    importedItems: {
      new: "New",
      updated: "Updated",
      movedToWanted: "Moved to Wishlist",
      missing: "Unable to Import",
    },
  },
};
